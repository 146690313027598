var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"traffic"},[_c('div',{staticClass:"nav"},[_c('Carousel',{attrs:{"show":"1"}})],1),_c('div',{staticClass:"navs"},[_c('div',{staticClass:"navcon"},[_c('p',{staticClass:"nav_p"},[_c('span',{style:({
            background: _vm.show == 'TrafficGuide' ? '#197CC3' : '#fff',
            color: _vm.show == 'TrafficGuide' ? '#fff' : '#000',
            zIndex: _vm.show == 'TrafficGuide' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('TrafficGuide', $event)}}},[_vm._v(_vm._s(_vm.$t("language.TrafficGuide"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'ParkingInformation' ? '#197CC3' : '#fff',
            color: _vm.show == 'ParkingInformation' ? '#fff' : '#000',
            zIndex: _vm.show == 'ParkingInformation' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('ParkingInformation', $event)}}},[_vm._v(_vm._s(_vm.$t("language.ParkingInformation"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'SurroundingHotels' ? '#197CC3' : '#fff',
            color: _vm.show == 'SurroundingHotels' ? '#fff' : '#000',
            zIndex: _vm.show == 'SurroundingHotels' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('SurroundingHotels', $event)}}},[_vm._v(_vm._s(_vm.$t("language.SurroundingHotels"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'ScenicSpot' ? '#197CC3' : '#fff',
            color: _vm.show == 'ScenicSpot' ? '#fff' : '#000',
            zIndex: _vm.show == 'ScenicSpot' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('ScenicSpot', $event)}}},[_vm._v(_vm._s(_vm.$t("language.ScenicSpot")))])]),_c('p',[_c('van-icon',{attrs:{"color":"#999999","name":"wap-home","size":"20"}}),_c('span',[_vm._v(_vm._s(_vm.$t("language.current"))+" "),_vm._v(" : "+_vm._s(_vm.$t("language.HOMEpage"))+" "),_vm._v(" > "),_c('span',{staticClass:"spans",domProps:{"innerHTML":_vm._s(_vm.$t('language.PeripheralServices'))}}),_vm._v(" > "+_vm._s(_vm.$t(("language." + _vm.show)))+" ")])],1)])]),(_vm.show == 'SurroundingHotels')?_c('div',{staticClass:"content"},[_c('Aroundthehotel',{attrs:{"tablelists":_vm.tablelist}})],1):(_vm.show == 'ParkingInformation')?_c('div',{staticClass:"content"},[_c('Parkinginformation')],1):(_vm.show == 'ScenicSpot')?_c('div',{staticClass:"content"},[_c('Touristattractions')],1):(_vm.show == 'TrafficGuide')?_c('div',{staticClass:"content"},[_c('Transportationguide')],1):_vm._e(),_c('div',{staticClass:"bot"},[_c('Bottom')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }