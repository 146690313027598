<template>
    <!-- 交通指南 -->
   <div class="content">
          <div class="contentbox">
              <div class="trafficontent">
                  <div class="trafficontentimg">
                      <div class="postrafficimg">
                          <p class="postrafficimgtop"><span id="jt" v-html="$t('language.oftransportation')">
                              <!-- 交通方式 -->
                              </span><img class="yjt" src="../../assets/image/1628148527(1).jpg" alt=""></p>
                          <p class="eng">
                              TRANSPORTATION
                          </p>
                      </div>
                  </div>
                  <div class="contentright">
                    <div class="rightboxitem">
                        <img class="imglogo" src="../../assets/image/高速路出口@2x.png" alt="">
                        <div class="itemrightdiv">
                            <p>
                                {{$t('language.expressway')}}
                                <!-- 高速公路 -->
                            </p>
                            <p class="characters">
                                {{$t('language.kilometersofexpressways')}}
                                <!-- 截止2020年，通车高速公路670公里，形成以沈海、荣乌、威青、烟海、龙青、蓬栖、文莱高速和国道204、206等高等级公路为主框架、四通八达的现代化公路网，对外出行十分便捷。 -->
                            </p>
                        </div>
                    </div>
                    <div class="rightboxitem">
                        <img class="imglogo" style="width:79px;height:79px;display:block" src="../../assets/image/高铁 动车@2x.png" alt="">
                        <div class="itemrightdiv">
                            <p>
                                {{$t('language.Railandhighspeedrail')}}
                                <!-- 铁路与高铁 -->
                            </p>
                            <p class="characters">
                                {{$t('language.threelongitudinal')}}
                               <!-- 作为山东省“三纵三横”铁路网重要城市，青荣城际高速铁路1小时可达青岛，即将建成的环渤海城际高速铁路3小时可达北京，另通有烟台至大连轮渡铁路。 -->
                            </p>
                        </div>
                    </div>
                    <div class="rightboxitem">
                        <img class="imglogo" src="../../assets/image/机场@2x.png" alt="">
                        <div class="itemrightdiv">
                            <p>
                                {{$t('language.airport')}}
                                <!-- 机场 -->
                            </p>
                            <p class="characters">
                                {{$t('language.YantaiPenglaiInternational')}}
                                <!-- 截止2020年底，烟台蓬莱国际机场与94个国内、国际及地区城市通航,开通航线169条, 形成“面向日韩、贯通南北、连接西北、辐射西南”的航线网络格局。蓬莱国际机场年旅客吞吐量达1000万人次，机场二期工程将于2022年竣工。 -->
                            </p>
                        </div>
                    </div>
                    <div class="rightboxitem">
                        <img class="imglogo" src="../../assets/image/bus.png" alt="">
                        <div class="itemrightdiv">
                            <p>
                                {{$t('language.port')}}
                                <!-- 港口 -->
                            </p>
                            <p class="characters">
                                {{$t('language.Yantaiport')}}
                                <!-- 烟台港于1861年开港，是全国沿海25个重要枢纽港口和“一带一路“15个战略重要港口，货物吞吐量稳居全国前十。现有40万吨世界级矿石码头和30万吨世界级原油码头各1座，各类泊位242个，其中万吨级以上深水泊位74个。
烟台至大连轮渡实现6小时从山东半岛至辽东半岛，有效加深华北、华东与东北联系，2020年旅客吞吐量达580万人次。 -->
                            </p>
                        </div>
                    </div>
                    
                  </div>
              </div>
          </div>
          <div class="mapcontents">
                <img class="map" src="../../assets/image/图层 74@2x.png" alt="">
          </div>
   </div>
</template>

<script>
export default {
    methods: {
   
    },
}
</script>

<style scoped>
    .spans >>> span{
        font-size: 14px !important;
    }
    .characters{
        margin-right: 62px;
    }
    .rightboxitem P:nth-of-type(1){
        margin-left: 22px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 15px;
    }
    .rightboxitem P:nth-of-type(2){
        margin-left: 22px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
    }
    .rightboxitem{
        display: flex;
        margin-top: 58px;
    }
    .contentright{
        margin-left: 167px;
        /* margin-top: -55px; */
    }
  
     .navcon{
        font-size:14px;
        display: flex;
        justify-content: space-between;
        height: 70px;
    }
   .navconleft>span{
        display: inline-block;
        /* width: 114px; */
        padding: 0 20px;
        line-height: 71px;
        text-align: center;
        background-color: #fff;
        cursor:pointer;
    }
    .navconright{
        line-height: 71px;
    }
    .navs{
        width: 1400px;
        margin: 0 auto;
    }
    .navcon p:nth-of-type(2){
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .navcon p:nth-of-type(1) span:nth-of-type(1){
        background: #197CC3;
        color: #fff;
    }
    .contentbox{
       width: 100%;
       height: auto;
       background: #FAFAFA;
       padding: 60px 0;
    }
    .trafficontent{
        width: 1400px;
        /* height: 844px; */
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(106, 105, 105, 0.13);
        margin: 0 auto;
        padding: 53px 0 53px 67px;
        display: flex;
    }
    .trafficontentimg{
        width: 386px;
        height: 726px;
        background: url('../../assets/image/图层 70@2x.png') no-repeat;
        background-size: 100% 100%;
        padding-top: 52px;
        padding-left: 134px;
        box-sizing: border-box;
    }
    .postrafficimg{
        width: 358px;
        height: 89px;
        background: url('../../assets/image/矩形 36@2x.png') no-repeat;
        background-size: 100% 100%;
        padding: 18px 0 0 39px;
        box-sizing: border-box;
    }  
    .postrafficimgtop{
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        margin-bottom: 5px;
    }
    .yjt{
        width: 67px;
        height: 16px;
        vertical-align: super;
    }
    .eng{
        font-size: 18px;
        font-family: Arial;
        font-weight: 400;
        color: #FFFFFF;
    }
    .imglogo{
        width: 76px;
        height: 86px;
        display: block;
    }
    .mapcontents{
        width: 100%;
        height: auto;
        background: #FAFAFA;
    }
    .map{
        width: 1470px;
        height: auto;
        display: block;
        margin: 0 auto;
    }
</style>