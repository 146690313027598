<template>
<!-- 旅游景点 -->
  <div class="touristattractions">
      <div class="content">
        <div class="imgtop">
          <p>
            <span>
              {{$t('language.PenglaiPavilionScenicArea')}}
              <!-- 蓬莱阁风景区 -->
            </span>
          </p>
          <p>
            <span v-html="$t('language.YantaiGoldenBeachSeasidePark')">
              <!-- 烟台金沙滩海滨公园 -->
            </span>
          </p>
          <p>
            <span>
              {{$t('language.YantaiMountainPark')}}
              <!-- 烟台山公园 -->
            </span>
          </p>
        </div>
        <div class="imgbot">
          <p class="left">
            <span>
              {{$t('language.PenglaiOceanPolarWorld')}}
              <!-- 蓬莱海洋极地世界 -->
            </span>
          </p>
          <div class="imgcent">
            <p>
              <span v-html="$t('language.YantaiyangmaIslandtouristresort')">
                <!-- 烟台养马岛旅游度假区 -->
              </span>
            </p>
            <p>
              <span v-html="$t('language.Yantaimagneticmountainhotspringtown')">
                <!-- 烟台磁山温泉小镇 -->
              </span>
            </p>
            <p>
              <span>
                {{$t('language.ChangyuWineCultureMuseum')}}
                <!-- 张裕酒文化博物馆 -->
              </span>
            </p>
          </div>
          <p class="right">
            <span>
              {{$t('language.LongIslandScenictouristarea')}}
              <!-- 长岛风景旅游区 -->
            </span>
          </p>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  span{
    display: block;
    width: 275px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 28px;
    background: rgba(0, 0, 0, 0.7);
    margin: 0 auto;
  }
  .touristattractions{
    width: 100%;
    height: auto;
    background: #FAFAFA;
    padding-top: 66px;
  }
  .content{
    width: 1400px;
    height: auto;
    margin: 0 auto 123px;
  }
  .imgtop{
    width: 100%;
    height: auto;
  }
  .imgtop p{
    width: 445px;
    height: 314px;
    margin-right: 32px;
    margin-bottom: 24px;
    float: left;
    padding: 220px 0 0 0;
    box-sizing: border-box;
  }
  .imgtop p:nth-of-type(1){
    background: url('../../assets/image/蓬莱阁@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .imgtop p:nth-of-type(2){
    background: url('../../assets/image/金沙滩@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .imgtop p:nth-last-of-type(1){
     margin-right: 0;
     background: url('../../assets/image/烟台山@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .left{
    width: 349px;
    height: 671px;
    float: left;
    margin-right: 18px;
    background: url('../../assets/image/海洋极地世界@2x.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 549px;
    box-sizing: border-box;
  }
  .imgbot{
    width: 100%;
    height: auto;
    display: flex;
  }
  .right{
    width: 349px;
    height: 671px;
    background: url('../../assets/image/长岛@2x.png') no-repeat;
    background-size: 100% 100%;
    float: left;
    padding-top: 549px;
    box-sizing: border-box;
  }
  .imgcent{
    width: 663px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 18px;
  }
  .imgcent p:nth-of-type(1){
    width: 323px;
    height: 261px;
    background: url('../../assets/image/a1.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    margin-right: 17px;
    padding-top: 167px;
    box-sizing: border-box;
  }
  .imgcent p:nth-of-type(2){
    width: 323px;
    height: 261px;
    margin-bottom: 20px;
    background: url('../../assets/image/磁山@2x.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 167px;
    box-sizing: border-box;
  }
  .imgcent p:nth-of-type(3){
    width: 663px;
    height: 390px;
    background: url('../../assets/image/张裕@2x.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 266px;
    box-sizing: border-box;
  }
</style>