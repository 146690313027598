<template>
  <div class="traffic">
    <div class="nav">
      <Carousel show="1" />
    </div>
    <div class="navs">
      <div class="navcon">
        <p class="nav_p">
          <!-- 交通指南 -->
          <span
            :style="{
              background: show == 'TrafficGuide' ? '#197CC3' : '#fff',
              color: show == 'TrafficGuide' ? '#fff' : '#000',
              zIndex: show == 'TrafficGuide' ? '5' : '',
            }"
            @click="gopage('TrafficGuide', $event)"
            >{{ $t("language.TrafficGuide") }} <b>|</b>
          </span>
          <!-- 停车信息 -->
          <span
            :style="{
              background: show == 'ParkingInformation' ? '#197CC3' : '#fff',
              color: show == 'ParkingInformation' ? '#fff' : '#000',
              zIndex: show == 'ParkingInformation' ? '5' : '',
            }"
            @click="gopage('ParkingInformation', $event)"
            >{{ $t("language.ParkingInformation") }} <b>|</b></span
          >
          <!-- 周边酒店 -->
          <span
            :style="{
              background: show == 'SurroundingHotels' ? '#197CC3' : '#fff',
              color: show == 'SurroundingHotels' ? '#fff' : '#000',
              zIndex: show == 'SurroundingHotels' ? '5' : '',
            }"
            @click="gopage('SurroundingHotels', $event)"
            >{{ $t("language.SurroundingHotels") }} <b>|</b></span
          >
          <!-- 旅游景点 -->
          <span
            :style="{
              background: show == 'ScenicSpot' ? '#197CC3' : '#fff',
              color: show == 'ScenicSpot' ? '#fff' : '#000',
              zIndex: show == 'ScenicSpot' ? '5' : '',
            }"
            @click="gopage('ScenicSpot', $event)"
            >{{ $t("language.ScenicSpot") }}</span
          >
        </p>
        <p>
          <van-icon color="#999999" name="wap-home" size="20" />
          <span
            >{{ $t("language.current") }}
            <!-- 当前位置 -->
            : {{ $t("language.HOMEpage") }}
            <!-- 首页 -->
            >
            <span
              v-html="$t('language.PeripheralServices')"
              class="spans"
            ></span>
            <!-- 周边服务 -->
            > {{ $t(`language.${show}`) }}
            <!-- 周边酒店 -->
          </span>
        </p>
      </div>
    </div>
    <div class="content" v-if="show == 'SurroundingHotels'">
      <Aroundthehotel :tablelists="tablelist" />
    </div>
    <div class="content" v-else-if="show == 'ParkingInformation'">
      <Parkinginformation />
    </div>
    <div class="content" v-else-if="show == 'ScenicSpot'">
      <Touristattractions />
    </div>
    <div class="content" v-else-if="show == 'TrafficGuide'">
      <Transportationguide />
    </div>
    <div class="bot">
      <Bottom />
    </div>
  </div>
</template>

<script>
import Aroundthehotel from "../../components/pcPeripheralservices/Aroundthehotel.vue";
import Parkinginformation from "../../components/pcPeripheralservices/Parkinginformation.vue";
import Touristattractions from "../../components/pcPeripheralservices/touristattractions.vue";
import Transportationguide from "../../components/pcPeripheralservices/transportationguide.vue";
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
export default {
  components: {
    Carousel,
    Bottom,
    Aroundthehotel,
    Parkinginformation,
    Touristattractions,
    Transportationguide,
  },
  data() {
    return {
      show: "",
      tablelist: [],
    };
  },
  created() {
    this.showcomponent();
    // 酒店信息
    this.hotelinformation();
  },
  mounted() {},
  methods: {
    scroll() {
      let traffic = document.getElementsByClassName("traffic")[0];
      this.$nextTick(() => {
        setTimeout(() => {
          let targetbox = document.getElementsByClassName("navs")[0];
          traffic.scrollTop = targetbox.offsetTop;
        });
      });
    },
    // 酒店信息
    hotelinformation() {
      let data = {};
      this.$api.hotelinformation(data, (res) => {
        if (res.data.code == 200) {
          this.tablelist = res.data.data;
          this.scroll();
        }
      });
    },
    showcomponent() {
      if (this.$route.query.path == "交通指南") {
        this.show = "TrafficGuide";
      } else if (this.$route.query.path == "停车信息") {
        this.show = "ParkingInformation";
      } else if (this.$route.query.path == "周边酒店") {
        this.show = "SurroundingHotels";
      } else if (this.$route.query.path == "旅游景点") {
        this.show = "ScenicSpot";
      } else if (this.$route.query.path == "/") {
        this.show = "TrafficGuide";
      } else if (this.$route.query.path == undefined) {
        this.show = "TrafficGuide";
      }
    },
    gopage(val, e) {
      if (val == "TrafficGuide") {
        this.$router.replace({ path: "/traffic", query: { path: `交通指南` } });
      } else if (val == "ParkingInformation") {
        this.$router.replace({ path: "/traffic", query: { path: `停车信息` } });
      } else if (val == "SurroundingHotels") {
        this.$router.replace({ path: "/traffic", query: { path: `周边酒店` } });
      } else if (val == "ScenicSpot") {
        this.$router.replace({ path: "/traffic", query: { path: `旅游景点` } });
      }
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
    },
  },
};
</script>

<style scoped>
.spans >>> span {
  font-size: 15px !important;
}
.traffic {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.bot {
  width: 100%;
}
.content {
  width: 100%;
}
.traffic::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.nav {
  width: 100%;
  height: 100%;
}
.navcon {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.navs {
  width: 1400px;
  margin: 0 auto;
}
.navcon p:nth-of-type(2) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navconright {
  line-height: 71px;
}
.navcon p {
  font-size: 15px;
  display: inline-block;
  line-height: 70px;
}
.nav_p :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.nav_p :hover b {
  display: none !important;
}
.nav_p span {
  position: relative;
}
.nav_p span b {
  position: absolute;
  z-index: 2 !important;
  right: -3px;
}
.navcon p:nth-of-type(1) {
  height: 100%;
  float: left;
}
.navcon p:nth-of-type(1) span {
  padding: 0 20px;
  float: left;
  text-align: center;
}
.navcon p:nth-of-type(1) span:nth-last-of-type(1) {
  border-right: 0;
}
</style>