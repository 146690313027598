<template>
<!-- 周边酒店 -->
       <div class="content">
        <div class="navbox">
            <div class="navtop">
              <div class="tops">
                  <img src="../../assets/image/集中商业-转角透视.png" alt="">
                  <img src="../../assets/image/旅游共享中心商业内街 (2).png" alt="">
              </div>
              <div class="center">
                  <img src="../../assets/image/中央花园鸟瞰图@2x.png" alt="">
                  <img src="../../assets/image/整体大鸟瞰图@2x.png" alt="">
                  <img src="../../assets/image/曝光度 1@2x.png" alt="">
              </div>
              <div class="navbot">
                  <div class="topspan">
                      <div class="bottit">
                          {{$t('language.WestinHotels')}}
                          <!-- 威斯汀酒店群 -->
                      </div><p class="jibi">
                          <!-- {{$t('language.FiveStar')}} -->
                          <!-- 五星级 -->
                          <img style="margin-right: 5px;" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                          <img style="margin-right: 5px;" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                          <img style="margin-right: 5px;" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                          <img style="margin-right: 5px;" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                          <img src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                      </p>
                  </div>
                  <p class="botcent"></p>
                  <div class="botcbox">
                      <p class="botcontent">
                          {{$t('language.Locatednextto')}}
                      <!-- 位于开发区八角湾国际会展中心旁，占地面积5.4万平方米，由三座国际知名星级酒店和一座服务型公寓组成，客房总数约1200间。 -->
                    </p>
                    <p class="botcontent">
                        {{$t('language.WestinHotelconstruction')}}
                        <!-- 威斯汀酒店，建筑面积4.5万平方米，配置面积55平米、层高4.2米的客房269间。 雅乐轩酒店，建筑面积3万平方米，配置面积36平方米、层高3.6米的客房361间。 -->
                    </p>
                    <p class="botcontent">
                        {{$t('language.TheHyattPlacehas336')}}
                        <!-- 凯悦嘉轩酒店，设有面积36平方米、层高3.6米的客房336间。  -->
                    </p>
                    <p class="botcontent">
                        {{$t('language.Thefirstfloorof')}}
                        <!-- 服务型公寓首层与凯悦嘉轩酒店相连接，设有面积36平方米的客房201间。 -->
                    </p>
                  </div>
              </div>
          </div>
          <div class="table">
                <div class="tabb">
                    <p class="more">
                        {{$t('language.Morehotels')}}
                        <!-- 更多酒店 -->
                    </p>
                    <table class="tabba" border="1" cellPadding="0" cellSpacing="0" bordercolor="#EBEBEB">
                        <tr>
                            <td style="background: #0F6FB4; color: #fff;">
                                {{$t('language.hotelname')}}
                                <!-- 酒店名称 -->
                                </td>
                            <td style="background: #197BC2; color:#fff;">
                                {{$t('language.starlevel')}}
                                <!-- 星级 -->
                            </td>
                            <td style="background: #2285CD; color:#fff;">
                                {{$t('language.hoteladdress')}}
                                <!-- 酒店地址 -->
                            </td>
                            <td style="background: #3092D9; color:#fff;">
                                {{$t('language.Mileage')}}
                                <!-- 里程（公里） -->
                            </td>
                            <td style="background: #3D9EE4; color:#fff;">
                                {{$t('language.Drive')}}
                                <!-- 车程（min) -->
                            </td>
                            <td style="background: #4AA8EC; color:#fff;">
                                {{$t('language.numberofrooms')}}
                                <!-- 房间总数 -->
                            </td>
                        </tr>
                        <tr v-for="(item,index) in tablelists" :key="index">
                            <td>
                                {{item.hotelName}}
                                <!-- 烟台金海岸希尔顿酒店 -->
                            </td>
                            <td class="starlevel">
                                <img v-for="(items) in Number(item.star)" :key="items" src="../../assets/image/lALPDg7mRkdgwScSFA_20_18.png" alt="">
                                <!-- {{item.star}} -->
                                
                            </td>
                            <td>
                                {{item.address}}
                                <!-- 开发区宁波路1号 -->
                            </td>
                            <td>
                                {{item.mileage}}
                            </td>
                            <td>
                                {{item.drive}}
                            </td>
                            <td>
                                {{item.homeNum}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
      </div>
</template>

<script>
export default {
    props:['tablelists'],
    methods: {
    
    },
}
</script>

<style scoped>
     .spans >>> span{
        font-size: 14px !important;
    }
    .more{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: 180px;
        text-align: center;
        border-left: 5px solid #197CC3;
        margin: 20px 0;
    }
    .tabba td{
        width: 278px;
        height: 58px;
    }
    .tabba{
        width: 100%;
        text-align: center;
    }
    .starlevel img{
        margin-right: 5px;
    }
    .tabb{
        width: 1399px;
        height: auto;
        padding: 30px;
        box-sizing: border-box;
        background: #FFFFFF;
        margin: 0 auto;
        margin-top: 30px;
    }
    .table{
        width: 100%;
        height: auto;
        background: #F5F5F5;
        padding-bottom: 50px;
        box-sizing: border-box;
    }
    .botcbox{
        margin-top: 35px;
    }
    .botcontent{
        font-size: 14px;
        color: #808080;
    }
    .phone img{
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
    .phone span{
        margin-left: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
    }
    .botcent{
        width: 25px;
        height: 2px;
        background: #197CC3;
        clear: both;
        margin: 15px 0;
    }
    .jibi{
        padding: 0 10px;
        height: 24px;
        border-radius: 6px;
        float: left;
        text-align: center;
        line-height: 24px;
        color: #fff;
        margin-left: 20px;
        margin-top: 8px;
    }
    .navbot{
        padding: 0 30px;
    }
    .bottit{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 15px;
        float: left;
    }
    .Aroundthehotel{
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    .nav{
        width: 100%;
        height: 100%;
        animation: examples 3s;
    }
    .bot{
        width: 100%;
    }
    .navtop{
        width: 1399px;
        height: auto;
        background: #FFFFFF;
        margin: 0 auto;
        padding-bottom: 30px;
        box-sizing: border-box;
    }
    .navtop .tops img{
        width:656px;
        height: auto;
        display: block;
    }
    .navtop .tops{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        box-sizing: border-box;
    }
    .navtop .center{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        padding-top: 0;
        box-sizing: border-box;
    }
    .navtop .center img{
        width: 428px;
        height: auto;
    }
    .content{
        width: 100%;
        height: auto;
    }
    .navbox{
        width: 100%;
        background: #F5F5F5;
        padding-top: 35px;
    }
    .tabba tr:nth-of-type(2n){
        background: #fff;
    }
    .tabba tr:nth-of-type(2n+1){
        background: #F5F5F5;
    }
</style>