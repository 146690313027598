<template>
<!-- 停车信息 -->
  <div class="concent">
      正在建设中
  </div>
</template>

<script>
export default {

}
</script>

<style>
.concent{
  width: 90%;
  margin-left: 150px;
  height: 100px;
  margin-top: 50px;
}
</style>